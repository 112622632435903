import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { CustomModalService } from "../../../../components/custom-modal";
import * as alertify from "alertifyjs";
import { ToastrService } from "ngx-toastr";
import { SubscriberDetailsService } from "../subscriber-details.service";
import { AddSubscriberDetailsService } from "../../add-subscriber-details/add-subscriber-details.service";

@Component({
  selector: "app-change-user-name",
  templateUrl: "./change-user-name.component.html",
  styleUrls: ["./change-user-name.component.scss"],
})
export class ChangeUserNameComponent implements OnInit {
  @Input() oldUserName:string;
  @Input() custId: number;
  submitted: boolean = false;

  invalidUserNameAsPerApi: boolean = false;
  invalidUserNameAsPerApiMsg: string = "";

  form = new FormGroup({
    userName: new FormControl("",[Validators.required,this.cannotContainSpace,Validators.pattern('^[a-z0-9_]+$')]),
    remarks: new FormControl("", [Validators.required]),
  });

  constructor(
    private customModalService: CustomModalService,
    private cdRef: ChangeDetectorRef,
    public addSubscriberDetailsService: AddSubscriberDetailsService,
    private SubscriberDetailsService: SubscriberDetailsService,
    private tosterservice: ToastrService,
  ) {
   
  }

  ngOnInit() {

  }

  cannotContainSpace(control: AbstractControl) : ValidationErrors | null {
    if(control && control.value && (control.value as string).indexOf(' ') >= 0){
        return {cannotContainSpace: true}
    }
    return null;
  }

  getIsinvalidUserNameAsPerApi() {
    return this.invalidUserNameAsPerApi;
  }

 
  checkUniqueUserName(el) {
    let value = el.target.value;
    if (value && value.length) {
      if(!this.spaceCheck(value)){
        this.invalidUserNameAsPerApi = false;
        this.addSubscriberDetailsService.checkUniqueCustomerByUsername(value).subscribe(result => {
          if (result.responseCode !== 200) {
            this.invalidUserNameAsPerApi = true;
            this.invalidUserNameAsPerApiMsg = result.responseMessage;
            this.onTouchedandChange();
          } else {
            this.invalidUserNameAsPerApi = false;
            this.invalidUserNameAsPerApiMsg = '';
            this.onTouchedandChange();
          }
        });
      }
    } else {
      this.invalidUserNameAsPerApi = false;
      this.invalidUserNameAsPerApiMsg = '';
    }
  }

  spaceCheck(value){
    return value.indexOf(' ') >= 0;
  }

  onTouchedandChange() {
    let value = this.form.value;
    this.onChange(value);
    this.onTouched(value);
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  clearMsgUnique() {
    if (
      this.invalidUserNameAsPerApi === true &&
      this.form.get("userName").valid === false
    ) {
      this.invalidUserNameAsPerApiMsg = "";
    }
  }

  onClickSaveForm() {
    setTimeout(() => {
      if (
        this.form.valid === true &&
        !this.invalidUserNameAsPerApi
      ) {
        let self = this;
        alertify.confirm(
          "Username",
          "Are You Sure You want to Update Username?",
          function () {
            self.insertDetailsOnDb();
          },
          function () {}
        );
      } else {
        this.form.markAllAsTouched();
      }
    }, 700);
  }

  insertDetailsOnDb() {
    if (this.form.valid === true) {
      let formatedInputData: any = this.form.value;
      formatedInputData.custId = Number(this.custId);
      this.SubscriberDetailsService
        .updateUserName(formatedInputData)
        .subscribe(
          (result) => {
            this.cdRef.detectChanges();
            if (result.responseCode !== 200 && result.responseMessage) {
              this.tosterservice.error(result.responseMessage);
            } else {
              this.tosterservice.success("Username Update successfully");
              this.customModalService.close("user-name", result);
            }
          },
          (err) => {
            this.tosterservice.error(err.error.status + " " + err.error.ERROR);
          }
        );
    }
  }

  closeModal(id: string) {
    this.customModalService.close(id, { close_from: "close_button" });
  }
}
